import { NgClass, NgTemplateOutlet } from '@angular/common';
import {
  ChangeDetectionStrategy,
  Component,
  ElementRef,
  inject,
  Input,
  OnChanges,
  OnDestroy,
  output,
  TemplateRef,
  ViewChild,
} from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { FormsModule, ReactiveFormsModule, Validators } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import {
  MatCheckboxChange,
  MatCheckboxModule,
} from '@angular/material/checkbox';
import { MatPseudoCheckboxModule } from '@angular/material/core';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { BaseFormControlComponent } from '@cca-common/forms';
import { WINDOW } from '@cca-environment';
import { IconComponent } from '@cca/ui';
import { provideTranslocoScope, TranslocoModule } from '@jsverse/transloco';
import { DropdownPosition, NgSelectModule } from '@ng-select/ng-select';
import {
  AddTagFn,
  GroupValueFn,
} from '@ng-select/ng-select/lib/ng-select.component';
import { NgxTippyModule, NgxTippyProps } from 'ngx-tippy-wrapper';
import { BehaviorSubject, combineLatest, filter, Subject } from 'rxjs';

@Component({
  selector: 'cca-auto-complete-field',
  standalone: true,
  providers: [provideTranslocoScope('autocompleteField')],
  templateUrl: './auto-complete-field.component.html',
  styleUrls: ['./auto-complete-field.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [
    FormsModule,
    NgSelectModule,
    MatCheckboxModule,
    ReactiveFormsModule,
    MatPseudoCheckboxModule,
    NgTemplateOutlet,
    TranslocoModule,
    IconComponent,
    MatFormFieldModule,
    MatInputModule,
    NgxTippyModule,
    NgClass,
    MatButtonModule,
  ],
})
export class AutoCompleteFieldComponent
  extends BaseFormControlComponent<unknown>
  implements OnChanges, OnDestroy
{
  private window = inject(WINDOW);
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  @ViewChild('ngSelect', { static: false }) ngSelect!: any;
  @ViewChild('searchInput', { static: false })
  searchInput?: ElementRef<HTMLInputElement>;

  private _isOpenSearch$ = new BehaviorSubject<boolean>(false);

  @Input() bindLabel!: string;
  @Input() bindValue!: string;
  @Input() markFirst = true;
  @Input() placeholder!: string;
  @Input() notFoundText!: string;
  @Input() typeToSearchText!: string;
  @Input() addTagText!: string;
  @Input() loadingText!: string;
  @Input() clearAllText!: string;
  @Input() clearAll = true;
  @Input() appearance = 'outline';
  @Input() fixedPlaceholder = true;
  @Input() dropdownPosition: DropdownPosition = 'auto';
  @Input() appendTo = 'body';
  private _loading$ = new BehaviorSubject<boolean>(false);
  protected _loading = false;
  @Input()
  set loading(value: boolean) {
    this._loading = value;
    this._loading$.next(value);
  }

  @Input() closeOnSelect = true;
  @Input() hideSelected = false;
  @Input() selectOnTab = false;
  @Input() openOnEnter!: boolean;
  @Input() maxSelectedItems!: number;
  @Input() groupBy!: string | ((value: unknown) => unknown);
  @Input() groupValue!: GroupValueFn;
  @Input() bufferAmount = 4;
  @Input() virtualScroll!: boolean;
  @Input() selectableGroup = false;
  @Input() selectableGroupAsModel = true;
  @Input() searchFn = null;
  @Input() trackByFn: unknown = null;
  @Input() clearOnBackspace = true;
  @Input() labelForId = null;
  @Input() inputAttrs: { [key: string]: string } = {};
  @Input() tabIndex!: number;
  @Input() readonly = false;
  @Input() searchWhileComposing = true;
  @Input() minTermLength = 0;
  @Input() editableSearchTerm = false;
  @Input() showChips = false;
  @Input() showTooltip = false;
  @Input() showStaticLabel = false;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  @Input() showBadgeFn: (item?: any) => boolean = () => true;
  @Input() badgePlacement: 'left' | 'right' = 'right';
  @Input() tooltipOptions: NgxTippyProps = {
    placement: 'bottom',
    arrow: true,
    theme: 'dark',
    interactive: true,
    appendTo: document.body,
  };

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  @Input() keyDownFn = (_: KeyboardEvent) => true;

  @Input() typeahead: Subject<string> | undefined;
  @Input() multiple = false;
  @Input() addTag: boolean | AddTagFn = false;
  @Input() searchable = true;
  @Input() clearable = true;

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  @Input() items!: any[] | undefined | null;

  @Input() compareWith!: (a: unknown, b: unknown) => boolean;

  // output events
  blurEvent = output<unknown>();
  focusEvent = output<unknown>();
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  changeEvent = output<any>();
  openEvent = output<unknown>();
  closeEvent = output<unknown>();
  searchEvent = output<{
    term: string;
    items: unknown[];
  }>();
  clearEvent = output<unknown>();
  addEvent = output<unknown>();
  removeEvent = output<unknown>();
  scrollEvent = output<{ start: number; end: number }>();
  scrollToEndEvent = output<unknown>();

  // custom templates
  @Input() badgeTemplate: TemplateRef<unknown> | undefined;
  // @ContentChild(NgOptionTemplateDirective, { read: TemplateRef })
  // optionTemplate!: TemplateRef<unknown>;
  // @ContentChild(NgOptgroupTemplateDirective, { read: TemplateRef })
  // optgroupTemplate!: TemplateRef<unknown>;
  // @ContentChild(NgLabelTemplateDirective, { read: TemplateRef })
  // labelTemplate!: TemplateRef<unknown>;
  // @ContentChild(NgMultiLabelTemplateDirective, { read: TemplateRef })
  // multiLabelTemplate!: TemplateRef<unknown>;
  // @ContentChild(NgHeaderTemplateDirective, { read: TemplateRef })
  // headerTemplate!: TemplateRef<unknown>;
  // @ContentChild(NgFooterTemplateDirective, { read: TemplateRef })
  // footerTemplate!: TemplateRef<unknown>;
  // @ContentChild(NgNotFoundTemplateDirective, { read: TemplateRef })
  // notFoundTemplate!: TemplateRef<unknown>;
  // @ContentChild(NgTypeToSearchTemplateDirective, { read: TemplateRef })
  // typeToSearchTemplate!: TemplateRef<unknown>;
  // @ContentChild(NgLoadingTextTemplateDirective, { read: TemplateRef })
  // loadingTextTemplate!: TemplateRef<unknown>;
  // @ContentChild(NgTagTemplateDirective, { read: TemplateRef })
  // tagTemplate!: TemplateRef<unknown>;
  // @ContentChild(NgLoadingSpinnerTemplateDirective, { read: TemplateRef })
  // loadingSpinnerTemplate!: TemplateRef<unknown>;

  // @ViewChild(forwardRef(() => NgDropdownPanelComponent))
  // dropdownPanel!: NgDropdownPanelComponent;
  // @ViewChild('searchInput', { static: true })
  // searchInput!: ElementRef<HTMLInputElement>;
  // @ViewChild('clearButton') clearButton!: ElementRef<HTMLSpanElement>;
  // @ContentChildren(NgOptionComponent, { descendants: true })
  // ngOptions!: QueryList<NgOptionComponent>;

  get isRequired(): boolean {
    return this.formControl.hasValidator(Validators.required);
  }

  constructor() {
    super();
    this.window?.addEventListener(
      'scroll',
      this._handleDropdownPanelAdjustPosition,
      true,
    );
    this._handleSearchInputFocus();
  }

  ngOnChanges() {
    if (this.items && this.items.length !== 0 && this.formControl) {
      this.setSelectedItemsOnTop(this.formControl.value);
    }
  }

  ngOnDestroy(): void {
    this.window?.removeEventListener(
      'scroll',
      this._handleDropdownPanelAdjustPosition,
      true,
    );
  }

  allSelected(): boolean {
    const selectedCount = this.ngSelect.selectedItems?.length;
    const allItemsCount = this.items?.length ?? 0;
    return selectedCount === allItemsCount;
  }

  partiallySelected(): boolean {
    const selectedCount = this.ngSelect.selectedItems?.length;
    const allItemsCount = this.items?.length ?? 0;
    return selectedCount > 0 && selectedCount < allItemsCount;
  }

  toggleAll(event: MatCheckboxChange) {
    this.items ??= [];
    const allItems = this.items.map((item) => {
      return this.bindValue ? item[this.bindValue] : item;
    });

    if (event.checked) {
      this.formControl.setValue(allItems);
    } else {
      this.formControl.setValue([]);
    }
  }

  /* eslint-disable-next-line @typescript-eslint/no-explicit-any */
  onChange(event: any) {
    if (this.multiple) {
      const selectedItemsValues = event.map(
        /* eslint-disable-next-line @typescript-eslint/no-explicit-any */
        (item: any) => item[this.bindValue],
      );
      this.setSelectedItemsOnTop(selectedItemsValues);
    }

    this.changeEvent.emit(event);
  }

  /* eslint-disable-next-line @typescript-eslint/no-explicit-any */
  onOpen(event: any) {
    this.openEvent.emit(event);
    this._isOpenSearch$.next(true);
  }

  private _handleSearchInputFocus() {
    combineLatest([
      this._loading$.asObservable(),
      this._isOpenSearch$.asObservable(),
    ])
      .pipe(
        filter(([loading, isOpen]) => !loading && isOpen),
        takeUntilDestroyed(this.destroyRef),
      )
      .subscribe(() => {
        setTimeout(() => {
          this.searchInput?.nativeElement?.focus();
          this._isOpenSearch$.next(false); // Resetting after focus
        });
      });
  }

  /* eslint-disable-next-line @typescript-eslint/no-explicit-any */
  private setSelectedItemsOnTop(selectedItems: any) {
    if (selectedItems) {
      const selected = this.items?.filter((item) =>
        selectedItems instanceof Array
          ? selectedItems.includes(item[this.bindValue])
          : item[this.bindValue] === selectedItems,
      );
      const unselected = this.items?.filter((item) =>
        selectedItems instanceof Array
          ? !selectedItems.includes(item[this.bindValue])
          : item[this.bindValue] !== selectedItems,
      );
      this.items = [...(selected ?? []), ...(unselected ?? [])];
    }
  }

  private _handleDropdownPanelAdjustPosition = (): void => {
    if (this.ngSelect?.isOpen) this.ngSelect.dropdownPanel.adjustPosition();
  };
}
